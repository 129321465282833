import { useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaRegEye
} from 'react-icons/fa';
import Image, { KEY_IMAGE } from 'src/components/image';

import { incrementCountview } from 'src/utils/increment-countview';
import { useDateUpdate, usePageViews } from 'src/utils/helper';
import { APP_IMG, NEXT_DEFAULT_COUNT_VIEW } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const CardFull = ({
  data,
  path,
  showHeadingTag = true,
  heightImg = '',
  showBadge = true,
  showPageviews = true,
  eventDataLayer
}) => {
  if (!data) return null;
  const [countView, setCountView] = useState(0);

  useEffect(async () => {
    await incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const dateUpdate = useDateUpdate(data?.published_at);
  const category = data.subCategory?.th ? data.subCategory.th : data?.section?.th;

  return (
    <a tabIndex='-1' href={path} onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}>
      <div className='card-wrapper'>
        <div className='card-full'>
          <div className={`img-scale-animate ${heightImg} img-overlay-70 mb-2`}>
            <Image image={data?.image} title={data?.title} type={KEY_IMAGE.CARD_MEDIUM} />
            {(showBadge && category) && <div className={`tag-box --sm top-left ms-1 mt-1 ${!showHeadingTag ? 'rm-heading' : ''}`}>{category}</div>}
            <div className='mask-content-lg'>
              {
                <div className='post-date-light'>
                  <ul>
                    <li><FaCalendarAlt /><span>{dateUpdate}</span></li>
                    {showPageviews && <>{countView > NEXT_DEFAULT_COUNT_VIEW && <li>
                      <FaRegEye /><span>{usePageViews(countView)}</span>
                    </li>}</>}
                  </ul>
                </div>
              }
              <h3>{data?.title}</h3>
            </div>
          </div>
          {
            data?.is_video && <div className='play-btn'>
              <img src={`${APP_IMG}/images/play.png`} alt='play' width='100%' height='100%' />
            </div>
          }
        </div>
      </div>
    </a>
  );
};

export default CardFull;
