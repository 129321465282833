import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import CardFull from 'src/components/cards/card-full';
import Carousels from 'src/components/carousels';
import { convertDatasets, convertObjPath, timestamp } from 'src/utils/helper';
import { GET } from 'src/utils/services';
import styled from 'styled-components';

const SectionElection2566 = ({ dataEvent }) => {
  if (_.isEmpty(dataEvent)) return null;
  const [resData, setResData] = useState();
  const backgroundDesktop = convertDatasets(dataEvent, 'background-desktop', true);
  const backgroundMobile = convertDatasets(dataEvent, 'background-mobile', true);
  const filterData = convertDatasets(dataEvent, 'filter-data', true);
  const type = convertDatasets(dataEvent, 'switch-key', true);

  let url = '';
  const [pathViewAll, setPathViewAll] = useState();
  if (_.isEmpty(backgroundDesktop) || _.isEmpty(backgroundMobile)) return null;
  useEffect(() => {
    (async () => {
      try {
        if (type === 'tag') {
          url = `/api/search/tag${convertObjPath({ name: filterData })}&limit=4`;
          setPathViewAll(`/tags/${filterData}`);
        } else if (type === 'categories' || type === 'category') {
          url = `/api/v1.0/categories/${filterData}?limit=4`;
          setPathViewAll(`/category/${filterData}`);
        }
        if (!_.isEmpty(filterData) && url !== '') {
          const res = await Promise.all([
            GET(
              url,
              false,
              true
            )
          ]);
          if (!_.isEmpty(res[0])) return setResData(res);
        }
      } catch (err) {
        console.error(`${timestamp()} ==========> EVENT_ELECTION-2566 ERROR :`, err?.message);
      }
    })();
  }, []);
  // if (_.isEmpty(resData)) return null;
  return (
    <SectionWrapper background={{
      desktop: backgroundDesktop,
      mobile: backgroundMobile
    }}>
      <img src={backgroundDesktop} className='background-desktop' />
      <img src={backgroundMobile} className='background-mobile' />
      <div className='card-list'>
        {!_.isEmpty(resData) && (
          resData[0]?.data?.slice(0, 4).map((item) => (
            <div className='card-item'>
              <CardFull
                data={item}
                path={item.link}
                showPageviews={false}
                showHeadingTag={false}
                showBadge = {false}
              //! DATALAYER
              />
            </div>
          ))
        )}
        {!_.isEmpty(resData) && (<a className='view-all' title={'เลือกตั้ง 2566'} href={pathViewAll || '#'}>
          <span>
            ดูทั้งหมด <BiRightArrowAlt size={20} />
          </span></a>)}
      </div>
      <div className='carousels'>
        <Carousels showArrow={true} slideShow={1}>
        {!_.isEmpty(resData) && (
          resData[0]?.data?.slice(0, 4).map((item) => (
            <div className='card-item'>
              <CardFull
                data={item}
                path={item.link}
                showPageviews={false}
                showHeadingTag={false}
                showBadge = {false}
              //! DATALAYER
              />
            </div>
          ))
        )}
        </Carousels>
        {!_.isEmpty(resData) && (<a className='view-all' title={'เลือกตั้ง 2566'} href={pathViewAll || '#'}>
          <span>
            ดูทั้งหมด <BiRightArrowAlt size={20} />
          </span></a>)}
      </div>
    </SectionWrapper >
  );
};

const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${props => props.background.desktop}); 
  @media (max-width: 1023px) {
    background-image: url(${props => props.background.mobile}); 
  }
  @media (max-width: 550px) {
            margin-bottom: 50px;
        }
  .background-desktop {
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .background-mobile{
    width: 100%;
    @media (min-width: 1024px) {
      display: none;
    }
  }
    .card-list{
      position: absolute;
      right: 6%;
      top: 5%;
      display: flex;
      flex-wrap: wrap;
      width: 40%;
      @media (max-width: 1300px) {
        width: 38%;
        }
      @media (max-width: 1023px) {
        width: 100%;
        top: 57%;
        left: 0;
        padding: 0 10px;
        }
        @media (max-width: 630px) {
          top: 53%;
        }
        @media (max-width: 550px) {
            display: none;
        }
        @media (min-width: 1024px) and (max-width: 1299px) {
            display: none;
        }
      .card-item{
        margin:10px;
        width: calc(50% - 20px);
        /* border: 2px solid #fff; */
        padding: 4px;
        background: linear-gradient(0deg, rgba(255,0,0,1) 0%, rgba(0,14,255,1) 100%);
        @media (max-width: 1023px) {
          &:nth-child(3),&:nth-child(4){
            display: none;
          }
        }
        @media (max-width: 425px) {
          margin:8px;
        }
        .card-wrapper{
          padding: 0;
          .card-img-wrapper{
            padding: 0;
          }
          .mb-2{
            margin: 0 !important;
          }
          .card-v{
            margin: 0;
          }
        }
        .pt-2{
          padding-top: 0 !important;
          .custom-card-title{
            font-size: 14px;
            -webkit-line-clamp: 1;
            color: #fff;
          }
        }
      }
    }
    .carousels{
        position: absolute;
        @media (max-width: 549px) {
        right: 0%;
        top: 46%;
        width: 100%;
        padding: 10px;
        }
        @media (min-width: 1024px) and (max-width: 1299px) {
            right: 5%;
            top: 5%;
            width: 40%;
            padding: 10px;
        }
        @media (min-width: 550px) and (max-width: 1023px) {
            display: none;
        }
        @media (min-width: 1300px) {
            display: none;
        }
    }
    .view-all{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        text-decoration: none;
        padding: 0 10px 0 0;
        font-size: 14px;
        transition: 0.5s ;
        color: #fefefe;
        @media (max-width: 550px) {
            padding: 0;
            }
            @media (min-width: 1024px) and (max-width: 1299px) {
            padding: 0;
        }
        span{
          display: flex;
          align-items: center;
          border-radius: 20px;
          background: #000063;
          border: 2px solid #c20023;
          padding: 1px 15px;
          font-size: 14px;
          transition: 0.5s;
          @media (max-width: 1023px) {
              margin-top: 20px;
            }
            @media (max-width: 550px) {
              font-size: 12px; 
              margin-top: 2px;
            }
            &:hover{
              background: #fefefe;
              color: #000063;
            }
          svg{
            padding-left: 2px;
          }
        }
      }
.resolution-image {
  padding-top: 57% !important;
}
 /* ---------------------------------- Arrow --------------------------------- */

 .slick-next,
  .slick-prev {
    z-index: 4;

    &:before {
      content: '';
    }
    svg {
      position: absolute;
      top: 0;
      transform: translate(0, -50%);

      width: 48px;
      height: 130px;
      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 38px;
      color: #fff;
    }
  }
  .slick-prev {
    left: 0px;
    svg {
      left: 0;
      padding-left: 5px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .slick-next {
    right: 0px;
    svg {
      right: 0;
      padding-right: 5px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
  .slick-disabled {
    display: none;
  }
`;

export default SectionElection2566;
